.appie-loader {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content {
	
}
.content ul li {
	margin: 20px 0;
}
.appie-loader {
	opacity: 0;
}
.appie-loader.active {
	opacity: 100;
}
.appie-visible {
	transition: all 0.5s ease-in-out;
}
.appie-visible {
	opacity: 0;
}
.appie-visible.active {
	opacity: 100;
}
/* .appie-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
	padding-left: 20 px;
	overflow: hidden;
	transition: all linear 0.65s;
}
.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title span {
	font-size: 30px !important;
	color: #fff !important;
}
.home-four-project {
	background: #eef1f6;
}
.home-four-footer {
	background: #ffffff;
}
.slick-dots li button:before {
	content: "";
}
.appie-showcase-item {
	margin-left: 10px;
	margin-right: 10px;
}
@media (min-width: 1400px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1200px;
	}
}

.appie-services-2-area.appie-services-8-area {
	overflow: hidden;
}
@media only screen and (min-width: 300px) and (max-width: 1024px) {
	.appie-services-8-area .service-thumb {
		margin-left: 0;
		margin-right: 0;
	}
}
.save-comment input:checked + label::after {
	font-family: "Font Awesome 5 Pro";
	content: "\f00c";
}
.appie-testimonial-area {
	overflow: hidden;
}
.testimonial-about-slider-active .testimonial-parent-item {
	display: flex !important;
	justify-content: center;
}
.testimonial-box {
	width: 770px !important;
}
.testimonial-box-about-slider-small-active
	.slick-center.slick-current
	.item
	.thumb
	img {
	width: 70px;
	display: inline-block;
}
.testimonial-box-about-slider-small-active .item .thumb img {
	display: inline-block;
	width: 40px;
}
.testimonial-box-about-slider-small-active .item .thumb {
	align-items: center;
}
.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
	margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
	.testimonial-about-slider-active .testimonial-box {
		box-shadow: none;
		background: none;
	}
}
@media only screen and (min-width: 770px) and (max-width: 1024px) {
	.testimonial-about-slider-active .slick-arrow.next {
		right: 15px;
	}
	.testimonial-about-slider-active .slick-arrow.prev {
		left: 15px;
	}
}
.web_analysis {
	text-align: justify;
	margin-top: 90px;
}
@media (max-width: 770px) {
	.web_analysis {
		text-align: left;
	}
}
.web_analysis .heads {
	text-align: center !important;
}
.analysis {
	margin-top: 30px;
}
.analysis h5 {
	margin-bottom: 10px;
}
.pre {
	display: flex;
	flex-direction: row;
}
.whoisitems {
	text-align: center;
}
.rawwho h6 {
	margin-bottom: 4px;
	font-weight: normal !important;
}
.rawwho h5 {
	margin-bottom: 7px;
}
.info-report {
	margin-top: 50px;
	box-shadow: none !important;
}
.info-report h4 {
	text-transform: capitalize;
}
/* Growth */
.growthF .growthF {
	font-family: "Milliard Book";
	overflow: hidden;
}
.growthHead {
	margin: 50px auto;
	line-height: 34px;
	width: 90%;
	text-align: center;
}
.growthF h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.01em;
	margin-bottom: 20px;
}
.growthF h1 {
	text-transform: capitalize;
	margin-bottom: 20px;
	margin-top: 90px !important;
}
/* @media (max-width: 1196px) {
	.growthHead {
		width: 80%;
	}
}
@media (max-width: 1033px) {
	.growthHead {
		width: 60%;
	}
}
@media (max-width: 991px) {
	.growthHead {
		width: 70%;
	}
} */
@media (max-width: 467px) {
	.growthHead {
		width: 90%;
	}
	.ggold {
		width: 90%;
		margin: auto;
	}
}
.growthBodyTop {
	width: 100%;
	display: flex;
	margin: auto;
}
.growthContent {
	line-height: 34px;
	text-align: center;
	padding: 40px;
}
.growthContent img {
	margin-bottom: 25px;
}

.growthBodyBottom {
	width: 100%;
	display: flex;
	margin: auto;
}
.growthBodyBottom h3 {
	width: 100% !important;
}
.GF {
	overflow: hidden;
}
.growthBodyBottom p {
	width: 90%;
	margin: auto;
}
.ggold {
	width: 80% !important;
	margin: auto;
}
@media (max-width: 991px) {
	.growthBodyTop {
		flex-direction: column;
	}
	.growthBodyBottom {
		flex-direction: column;
		width: 90%;
	}
}

@media (min-width: 992px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 95% !important;
	}
}
.container,
.container-lg,
.container-md,
.container-sm {
	max-width: 95% !important;
}
